.container {
  box-sizing: border-box;
  padding: 21px 24px 21px 30px;
  height: 80px;
  border-bottom: 1px solid #36394F;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #33364C;
  gap: 16px;
}

.rightActions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.lang {
  font-weight: 600;
}

.userDropdownContainer {
  position: relative;
}

.userDropdownCta {
  box-sizing: border-box;
  width: 247px;
  height: 60px;
  padding: 14px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: #45485E;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
}

.userDropdownCta:hover {
  opacity: 0.7;
}

.userInfo {
  margin-left: 10px;
}

.userName {
  color: #F2F2F2;
  font-weight: 600;
}

.userType {
  color: #6E718A;
  font-size: 14px;
}

.userActions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  background: #FFF;
  z-index: 1;
}

.userAction {
  width: 100%;
  padding: 1rem;
  border: 0;
  background: transparent;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
  transition: background 200ms ease-in-out;
}

.userAction:hover {
  background: #EFF0F8;
}
