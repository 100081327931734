.customers {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.customer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 16px;
  width: 292px;
  height: 212px;
  background: #36394F;
  border-radius: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #F9F9F9;
  cursor: pointer;
}

.customer > img {
  mix-blend-mode: lighten;
  filter: invert(1);
  scale: 1.4;
}
