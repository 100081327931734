.floatingButton {
  position: fixed;
  bottom: 4rem;
  right: 2rem;
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  background: linear-gradient(180deg, #CB1CB1 0%, #E010C2 100%);
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}

.floatingButton:hover {
  background: linear-gradient(180deg, #c038ac 0%, #c924b1 100%);
}
