.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 2rem;
}

.closeBtn {
  padding: 0 1.5rem;
  height: 4rem;
  display: flex;
  align-items: center;
  background: #36394F;
  border-radius: 0.75rem;
  cursor: pointer;
}

.submitBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 259px;
}

.submitBtn {
  width: fit-content;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #F9F9F9;
  height: 64px;
  background: linear-gradient(180deg, #CB1CB1 0%, #E010C2 100%);
  border-radius: 12px;
  padding: 8px 24px;
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.submitBtn > img {
  mix-blend-mode: lighten;
  filter: invert(1);
  scale: 1.2;
}

.closeQr {
  margin-left: 0.75rem;
  mix-blend-mode: lighten;
  filter: invert(1);
  scale: 1.2;
}

.exitQr {
  margin-right: 0.75rem;
  mix-blend-mode: lighten;
  filter: invert(1);
  scale: 1.2;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid #36394F;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.75rem 2rem 0.75rem;
  background: linear-gradient(166.89deg, #25283A88 3.33%, #0C0E1B88 89.92%) bottom right;
}

.centerLogo {
  padding-left: 126px;
  margin: 0 auto;
}
