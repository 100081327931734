.boards {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.board {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 16px;
  width: 292px;
  height: 222px;
  background: #36394F;
  border-radius: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #F9F9F9;
  cursor: pointer;
  overflow: hidden;
}

.board > img {
  width: 120px;
  height: 120px;
  mix-blend-mode: lighten;
  filter: invert(1);
  scale: 1.3;
}

.board > p {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headTitle {
  color: #FFF;
  text-align: center;
  font-size: 34px;
  line-height: 42px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.content {
  margin: auto 0;
}

.title {
  color: #FFF;
  text-align: center;
  font-size: 34px;
  line-height: 42px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.description {
  margin-top: 4px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #6E718A;
}

.input {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  border: 1px solid #36394F;
  width: 544px;
  height: 48px;
  background: #36394F;
  border-radius: 12px;
  letter-spacing: -0.02em;
  font-size: 16px;
  line-height: 24px;
  color: #F9F9F9;
  outline: none;
}

.input::placeholder {
  color: #6E718A;
}

.error {
  margin-top: 15px;
  font-size: 16px;
  line-height: 10px;
  color: #EB5757;
}

.errorInput {
  border: 1px solid #EB5757;
}
