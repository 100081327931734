.container {
  background: #FFF;
  color: #272A3F;
  height: 100vh;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
}

@media (max-width: 1023px) {
  .container {
    padding: 0;
  }
}

.card {
  margin: auto;
  width: 1095px;
  height: 695px;
  border-radius: 30px;
  box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.16);
}

@media (max-width: 1023px) {
  .card {
    margin: 0;
    height: auto;
    box-shadow: none;
  }
}

.cardContent {
  display: flex;
  height: 100%;
}

@media (max-width: 1023px) {
  .cardContent {
    flex-direction: column;
  }
}

.visuals {
  min-width: 550px;
  max-width: 623px;
  padding: 314px 106px 163px 86px;
  background: #D416B9 url("../../assets/icons/auth/visuals_background.svg") no-repeat right bottom;
  box-sizing: border-box;
  border-radius: 30px 0 0 30px;
}

@media (max-width: 1023px) {
  .visuals {
    width: 100%;
    min-width: auto;
    max-width: none;
    height: 280px;
    padding: 30px;
    border-radius: 0;
  }
}

.welcomeTitle {
  font-size: 61px;
  font-weight: bold;
  color: #FFF;
}

@media (max-width: 1023px) {
  .welcomeTitle {
    font-size: 40px;
  }
}

.welcomeSubtitle {
  margin-top: 24px;
  font-size: 18px;
  line-height: 130%;
  color: #FFBBF4;
}

@media (max-width: 1023px) {
  .welcomeSubtitle {
    font-size: 16px;
    margin-top: 18px;
  }
}

.welcomeTitle::selection, .welcomeSubtitle::selection {
  background: #9631FB;
  color: #FFF;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 54px 50px 50px;
  min-width: 330px;
  background: #FFF;
  border-radius: 0 30px 30px 0;
}

@media (max-width: 1023px) {
  .content {
    min-width: auto;
  }
}

.contentHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.logo {
  line-height: 0;
}

.form {
  flex: 1;
  margin-top: 50px;
  margin-bottom: 20px;
}

.contentLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
