.container {
  background: #1c2034;
  height: calc(100vh - 144px);
  padding: 32px;
  overflow: auto;
}

.titleContainer {
  flex: 1;
  display: flex;
  align-items: center;
  color: #FFF;
  margin-bottom: 32px;
}

.titleContainer > h1 {
  font-size: 34px;
  font-weight: 700;
}

.backBtn {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: #494C66;
  border: 0;
  margin-right: 20px;
  cursor: pointer;
  color: #F9F9F9;
  transition: opacity 200ms ease-in-out;
}

.backBtn:hover {
  opacity: 0.7;
}

.editBtn {
  margin-left: auto;
}

.nameField {
  display: flex;
  align-items: center;
}

.typeField {
  text-transform: capitalize;
}

.imgssl {
  position: fixed;
  right: 0;
  top: 0;
  width: 404px;
}

.detailsCard {
  display: flex;
  flex: 1;
  padding: 24px;
  background: #36394F;
  border-radius: 20px;
  gap: 24px;
}

.locationCard {
  width: 240px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: #36394F;
  border-radius: 20px;
  gap: 24px;
}

.locationName {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #FFFFFF;
}

.locationAddress {
  font-size: 16px;
  line-height: 24px;
  color: #6E718A;
}

.detailsImg {
  width: 240px;
  height: 240px;
  border-radius: 20px;
  background-color: #494C66;
  color: #fff;
}

.detailsInfoHeader {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  color: #F9F9F9;
  font-weight: 600;
  font-size: 28px;
}

.dimensions {
  color: #6E718A;
}

.description {
  margin-top: 24px;
  font-size: 18px;
  color: #F9F9F9;
  min-height: 52px;
  overflow: hidden;
}

.expandToggle {
  color: #D416B9;
  margin-left: 4px;
  font-size: 14px;
  cursor: pointer;
}

.detailsInfoBottom {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 600px;
}

.detailsParam {
  flex: 1;
  min-width: 200px;
}

.detailsParam > p {
  text-transform: uppercase;
  color: #6E718A;
  font-size: 14px;
  margin-bottom: 6px;
}

.detailsParam > span {
  color: #F9F9F9;
  font-size: 18px;
}

@media screen and (max-width: 1440px) {
  .titleContainer > h1 {
    font-size: 28px;
  }

  .backBtn {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 16px;
  }
}
