@import "./assets/fonts/ProximaNova/ProximaNova.css";

body {
  margin: 0;
  font-family: Proxima Nova, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::selection {
  background: #D416B9;
  color: #FFF;
}

a {
  color: #272A3F;
  text-decoration: none;
  transition: opacity 200ms ease-in-out;
}

a:hover {
  opacity: 0.75;
}

button, input, textarea {
  font-family: Proxima Nova, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.global-toast-wrapper {
  padding-top: 92px !important;
}

.global-toast-container {
  background: #272A3F !important;
  color: #F9F9F9 !important;
  border-radius: 16px !important;
}

.global-toast-container > button {
  color: #6E718A;
  opacity: 1;
}
