.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 8rem);
  height: calc(100% - 8rem);
  padding: 4rem;
  overflow: hidden;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
  background-color: #F9F9F9;
  border-radius: 1.25rem;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  overflow: auto;
}

.closeBtn {
  position: absolute;
  top: -3.125rem;
  right: 0;
  color: #FAFAFA;
  cursor: pointer;
}
