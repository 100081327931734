.container {
  background: #FFF;
  color: #272A3F;
  height: 100vh;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
}

@media (max-width: 1023px) {
  .container {
    padding: 0;
  }
}

.card {
  margin: auto;
  width: 1095px;
  height: 695px;
  border-radius: 30px;
  box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.16);
}

@media (max-width: 1023px) {
  .card {
    margin: 0;
    height: auto;
    box-shadow: none;
  }
}

.cardContent {
  display: flex;
  height: 100%;
}

@media (max-width: 1023px) {
  .cardContent {
    flex-direction: column;
  }
}

.visuals {
  min-width: 550px;
  max-width: 623px;
  padding: 314px 106px 163px 86px;
  background: #D416B9 url("../../assets/icons/auth/visuals_background.svg") no-repeat right bottom;
  box-sizing: border-box;
  border-radius: 30px 0 0 30px;
}

@media (max-width: 1023px) {
  .visuals {
    width: 100%;
    min-width: auto;
    max-width: none;
    height: 280px;
    padding: 30px;
    border-radius: 0;
  }
}

.welcomeTitle {
  font-size: 61px;
  font-weight: bold;
  color: #FFF;
}

@media (max-width: 1023px) {
  .welcomeTitle {
    font-size: 40px;
  }
}

.welcomeSubtitle {
  margin-top: 24px;
  font-size: 18px;
  line-height: 130%;
  color: #FFBBF4;
}

@media (max-width: 1023px) {
  .welcomeSubtitle {
    font-size: 16px;
    margin-top: 18px;
  }
}

.welcomeTitle::selection, .welcomeSubtitle::selection {
  background: #9631FB;
  color: #FFF;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 54px 50px 50px;
  min-width: 330px;
  background: #FFF;
  border-radius: 0 30px 30px 0;
}

@media (max-width: 1023px) {
  .content {
    min-width: auto;
  }
}

.contentHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.logo {
  line-height: 0;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
}

.form > h2 {
  font-size: 36px;
  font-weight: 600;
  line-height: 130%;
}

.form > p {
  margin-top: 28px;
  font-size: 20px;
  line-height: 130%;
}

.form > input {
  width: 100%;
  margin: 84px auto auto;
  padding-bottom: 10px;
  border: 0;
  border-bottom: 1px solid #272A3F;
  color: #272A3F;
  font-size: 22px;
  line-height: 28px;
  outline: 0;
  text-align: center;
  transition: border-bottom-color 200ms ease-in-out;
}

.form > input::placeholder {
  color: #E0E0E0;
}

.form > input:focus {
  border-bottom: 1px solid #D416B9;
}

.form > input:invalid:not(:focus) {
  border-bottom: 1px solid #E0E0E0;
}

.form > p.errorMessage {
  margin: auto;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #EB5757;
  min-height: 22px;
}

.controls {
  display: flex;
  gap: 20px;
}

.controls > .submitBtn {
  flex: 1;
  padding: 19px 22px;
  color: #FFF;
  background: #D416B9;
  font-size: 18px;
  font-weight: 600;
  border-radius: 67px;
  border: 0;
  outline: 0;
  transition: background-color 200ms ease-in-out, outline 200ms ease-in-out;
  cursor: pointer;
}

.controls > .submitBtn[disabled] {
  background: #E0E0E0;
  cursor: default;
}

.controls > .submitBtn:hover:not([disabled]) {
  background: #D416B9BF;
}

.controls > .submitBtn:focus, .submitBtn:focus-visible {
  outline: 2px solid #9631FB;
}

.backBtn {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  border: 1px solid #272A3F;
  background: transparent;
  cursor: pointer;
  color: #272A3F;
  transition: color 200ms ease-in-out, border-color 200ms ease-in-out;
}

.backBtn:hover {
  border: 1px solid #272A3FBE;
  color: #272A3FBE;
}

.form > a {
  display: block;
  margin-top: 11px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #CC1BB2;
  min-height: 22px;
}

.form > .signUp {
  margin-top: 18px;
  font-size: 14px;
}

.form > .signUp > a {
  color: #D416B9;
}

.contentLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
