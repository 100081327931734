.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.form > h2 {
  font-size: 36px;
  font-weight: 600;
  line-height: 130%;
}

.form > p {
  margin-top: 16px;
  font-size: 20px;
  line-height: 130%;
}

.icon {
  margin: auto;
}

.controls {
  margin-top: auto;
  margin-bottom: 36px;
  display: flex;
  gap: 20px;
}

.controls > .submitBtn {
  flex: 1;
  padding: 19px 22px;
  color: #FFF;
  background: #D416B9;
  font-size: 18px;
  font-weight: 600;
  border-radius: 67px;
  border: 0;
  outline: 0;
  transition: background-color 200ms ease-in-out, outline 200ms ease-in-out;
  cursor: pointer;
}

.controls > .submitBtn[disabled] {
  background: #E0E0E0;
  cursor: default;
}

.controls > .submitBtn:hover:not([disabled]) {
  background: #D416B9BF;
}

.controls > .submitBtn:focus, .submitBtn:focus-visible {
  outline: 2px solid #9631FB;
}
