.container {
  background: #1c2034;
  height: calc(100vh - 94px);
  padding: 14px 28px 0;
  display: flex;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  align-items: center;
  color: #FFF;
}

.titleContainer > h1 {
  font-size: 34px;
  font-weight: 700;
}

.backBtn {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: #494C66;
  border: 0;
  margin-right: 20px;
  cursor: pointer;
  color: #F9F9F9;
  transition: opacity 200ms ease-in-out;
}

.backBtn:hover {
  opacity: 0.7;
}

.nameField {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.typeField {
  text-transform: capitalize;
}

.actionsField {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.actionsField > svg {
  cursor: pointer;
  transition: color 200ms ease-in-out;
}

.actionsField > svg:hover {
  color: #D416B9;
}

.imgssl {
  position: fixed;
  right: 0;
  top: 0;
  width: 404px;
}

@media screen and (max-width: 1440px) {
  .titleContainer > h1 {
    font-size: 28px;
  }

  .backBtn {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 16px;
  }
}
