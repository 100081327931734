.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 88px;
}

.title {
  color: #FFF;
  text-align: center;
  font-size: 34px;
  line-height: 42px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.actions {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.actionCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: 212px;
  gap: 16px;
  padding: 24px;
  color: #F9F9F9;
  background: #36394F;
  border-radius: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  cursor: pointer;
}

.actionCardPrimary {
  background: linear-gradient(180deg, #CB1CB1 0%, #E010C2 100%);
}

.qrImg {
  mix-blend-mode: lighten;
  filter: invert(1);
  scale: 1.2;
}
