@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Regular.woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-RegularItalic.woff2');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Thin.woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-ThinItalic.woff2');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Light.woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-LightItalic.woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Semibold.woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-SemiboldItalic.woff2');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Bold.woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-BoldItalic.woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Extrabold.woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-ExtraboldItalic.woff2');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Black.woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-BlackItalic.woff2');
    font-weight: 900;
    font-style: italic;
}
