.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 184px;
}

.steps {
  margin: 48px auto 0;
  display: flex;
  align-items: center;
  gap: 4px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 112px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  border-radius: 8px;
  color: #F9F9F9;
}

.activeStep {
  background: #36394F;
}

.completedStepCheckmark {
  margin-bottom: 4px;
}

.completedStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #6FCF97;
}

.stepDescription {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #6E718A;
}

.stepContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  color: #FFF;
  text-align: center;
  font-size: 34px;
  line-height: 42px;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.description {
  margin-top: 4px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #6E718A;
}

.actions {
  width: calc(100% - 24px);
  min-width: 1024px;
  padding: 0 24px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.actionCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: 212px;
  gap: 16px;
  padding: 24px;
  color: #F9F9F9;
  background: #36394F;
  border-radius: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  cursor: pointer;
}

.actionCardPrimary {
  background: linear-gradient(180deg, #CB1CB1 0%, #E010C2 100%);
}
