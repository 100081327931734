.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.form > h2 {
  font-size: 36px;
  font-weight: 600;
  line-height: 130%;
}

.form > p {
  margin-top: 16px;
  font-size: 20px;
  line-height: 130%;
}

.form > a {
  display: block;
  margin-top: 11px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #CC1BB2;
  min-height: 22px;
}

.upload {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 146px;
  margin: 36px auto 0;
  border: 1px dashed #E0E0E0;
  color: #E0E0E0;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.upload > input {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 0;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.upload > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form > p.errorMessage {
  margin: auto;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #EB5757;
  min-height: 22px;
}

.controls {
  margin-top: auto;
  margin-bottom: 36px;
  display: flex;
  gap: 20px;
}

.controls > .submitBtn {
  flex: 1;
  padding: 19px 22px;
  color: #FFF;
  background: #D416B9;
  font-size: 18px;
  font-weight: 600;
  border-radius: 67px;
  border: 0;
  outline: 0;
  transition: background-color 200ms ease-in-out, outline 200ms ease-in-out;
  cursor: pointer;
}

.controls > .submitBtn[disabled] {
  background: #E0E0E0;
  cursor: default;
}

.controls > .submitBtn:hover:not([disabled]) {
  background: #D416B9BF;
}

.controls > .submitBtn:focus, .submitBtn:focus-visible {
  outline: 2px solid #9631FB;
}

.backBtn {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  border: 1px solid #272A3F;
  background: transparent;
  cursor: pointer;
  color: #272A3F;
  transition: color 200ms ease-in-out, border-color 200ms ease-in-out;
}

.backBtn:hover {
  border: 1px solid #272A3FBE;
  color: #272A3FBE;
}
