.selectWrapper {
  position: relative;
  width: 290px;
}

.smallLabel {
  position: absolute;
  top: 0;
  left: 19px;
  font-size: 10px;
  background: #FFF;
  border-radius: 4px;
  padding: 4px;
  opacity: 0;
  transition: all 200ms ease-in-out;
  pointer-events: none;
}

.smallLabelActive {
  top: 0;
  opacity: 1;
}

.label {
  font-size: 16px;
  font-weight: 600;
  color: #36394F;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.select {
  position: relative;
  background-color: #FFF;
  padding: 2px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
}

.selectDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.searchInput {
  flex: 1;
  padding: 17px 0;
  border: 0;
  font-size: 14px;
  outline: none;
}

.optionsList {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  border: 1px solid #272A3F;
  background-color: #FFF;
  border-radius: 4px;
  box-sizing: border-box;
  max-height: 134px;
  overflow: auto;
  z-index: 10001;
}

.option {
  padding: 15px 23px;
  transition: all 200ms ease-in-out;
}

.option:hover {
  background-color: #585d88;
  color: #FFF;
}

.icon {
  flex-shrink: 0;
  margin-left: 1rem;
  transition: all 200ms linear;
}

.iconInverted {
  flex-shrink: 0;
  margin-left: 1rem;
  transition: all 200ms linear;
  transform: rotate(180deg);
}
