.container {
  display: flex;
  gap: 24px;
}

.leftCards {
  flex: 1;
  display: flex;
  gap: 24px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 16px;
  width: 292px;
  height: 212px;
  background: #36394F;
  border-radius: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #F9F9F9;
  cursor: pointer;
}

.card > img {
  mix-blend-mode: lighten;
  filter: invert(1);
  scale: 1.2;
}

.rightCard {
  flex: 1;
  padding: 32px;
  gap: 24px;
  width: 596px;
  min-height: 404px;
  background: #272A3F;
  border-radius: 16px;
}

.rightCardTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #F9F9F9;
}

.item {
  margin-top: 28px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #6E718A;
}

.item > svg {
  flex-shrink: 0;
}

.itemActive {
  color: #FFF;
}

.removeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 8px 24px;
  gap: 12px;
  width: 132px;
  height: 64px;
  background: #36394F;
  border-radius: 12px;
  cursor: pointer;
}

.removeBtn > img {
  width: 40px;
  height: 40px;
  mix-blend-mode: lighten;
  filter: invert(1);
  scale: 1.2;
}

.itemTypeIcon {
  width: 80px;
  height: 64px;
  padding: 16px;
  background: #36394F;
  border-radius: 12px;
}

.completeStateCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 32px;
  gap: 4px;
  width: 596px;
  height: 204px;
  background: #36394F;
  border-radius: 16px;
}

.completeStateCardIcon {
  flex-shrink: 0;
  margin-bottom: 12px;
}

.completeStateCardTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #F9F9F9;
}

.completeStateCardDescription {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #6E718A;
}
