.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.assetCard {
  width: 100%;
  display: flex;
  background: #272A3F;
  border-radius: 16px;
  gap: 56px;
  overflow: hidden;
}

.assetImage {
  width: 370px;
  height: 100%;
  object-fit: cover;
}

.assetList {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 32px;
  padding: 30px;
}

.assetListItemLabel {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #6E718A;
}

.assetListItemValue {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #F9F9F9;
}

.assetSeparator {
  width: 100%;
  border: 1px solid #36394F;
}

.componentsBottomCards {
  display: flex;
  gap: 24px;
}

.componentsBottomCard {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #272A3F;
  padding: 32px;
  border-radius: 16px;
  min-height: 300px;
}

.componentsBottomCardTitle {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #F9F9F9;
}

.componentsBottomCardSubtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.item {
  margin-top: 28px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #6E718A;
}

.item > svg {
  flex-shrink: 0;
}

.itemActive {
  color: #FFF;
}

.removeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 8px 24px;
  gap: 12px;
  width: 132px;
  height: 64px;
  background: #36394F;
  border-radius: 12px;
  cursor: pointer;
}

.removeBtn > img {
  width: 40px;
  height: 40px;
  mix-blend-mode: lighten;
  filter: invert(1);
  scale: 1.2;
}

.itemTypeIcon {
  width: 80px;
  height: 64px;
  padding: 16px;
  background: #36394F;
  border-radius: 12px;
}
