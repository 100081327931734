.dropzone {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px dashed #D416B9;
  gap: 1rem;
  border-radius: 0.625rem;
  transition: opacity 200ms ease-in-out;
}

.uploadIcon {
  flex-shrink: 0;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.75rem;
  background: linear-gradient(180deg, #CB1CB1 0%, #E010C2 100%);
}

.fileInput {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.fileInput::file-selector-button {
  display: none;
}

.label {
  color: #6E718A;
  line-height: 1.5;
  overflow: hidden;
}

.fileName {
  word-break: break-all;
}
