.container {
  background: #1c2034;
  height: calc(100vh - 94px);
  padding: 14px 28px 0;
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
}

.profileHead {
  width: 100%;
  background: #36394F;
  border-radius: 20px;
  margin-bottom: 48px;
}

.profileHead > img {
  width: 100%;
}

.profileHeadRow {
  display: flex;
  align-items: center;
}

.profileIcon {
  width: 134px;
  height: 134px;
  border-radius: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #494C66;
  margin: -68px 18px 21px 24px;
}

.profileIcon > svg {
  width: 64px;
  height: 64px;
}

.username {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  padding-bottom: 20px;
}

.cards {
  display: flex;
  gap: 22px;
}

.leftColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.rightColumn {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.card {
  width: 100%;
  background: #36394F;
  border-radius: 20px;
}

.card a {
  color: #FFF;
}

.cardTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  padding: 16px 30px;
}

.userDetails {
  padding: 0 30px 8px;
}

.bio {
  padding: 16px 0;
  border-top: 1px solid #5A5D72;
  border-bottom: 1px solid #5A5D72;
}

.cardItemWithPadding {
  padding: 0 30px 16px;
}

.cardItem {
  border-bottom: 1px solid #5A5D72;
}

.rowWithIcon {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 16px 0;
}
