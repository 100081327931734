.modal {
  background-color: #EFF0F8;
  border-radius: 20px;
}

.title {
  padding: 24px 40px;
  background-color: #FFF;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.title > h1 {
  font-size: 28px;
}

.content {
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  overflow: auto;
}

.fieldsRow {
  display: flex;
  gap: 1rem;
}

.fieldsColumn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.category {
  display: flex;
  flex-direction: column;
  gap: 1rem
}

.subCategory {
  color: #6E718A;
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 0.75rem;
}

.bottomActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cancelBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  background: transparent;
  border: 1px solid #D416B9;
  border-radius: 52px;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
  color: #D416B9;
  outline: 0;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
}

.cancelBtn:hover {
  opacity: 0.7;
}

.createBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  background: linear-gradient(180deg, #CB1CB1 0%, #E010C2 100%);
  border-radius: 52px;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
  color: #FFF;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
}

.createBtn:disabled {
  opacity: 0.3;
  cursor: default;
}

.createBtn:hover:not(:disabled) {
  opacity: 0.7;
}
