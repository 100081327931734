.tabs {
  border-bottom: 1px solid #36394F;
  display: flex;
  gap: 32px;
}

.tab {
  color: #6E718A;
  font-size: 16px;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  height: 32px;
  padding: 0 1px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.activeTab {
  color: #D416B9;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  border-bottom: 1px solid #D416B9;
  margin-bottom: -1px;
  height: 32px;
  cursor: pointer;
  transition: color 200ms ease-in-out;
}

.tab:hover, .activeTab:hover {
  color: rgba(255, 69, 222, 0.8);
}
