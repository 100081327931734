.input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  border: 1px solid #36394F;
  width: 544px;
  height: 48px;
  background: #36394F;
  border-radius: 12px;
  letter-spacing: -0.02em;
  font-size: 16px;
  line-height: 24px;
  color: #F9F9F9;
  outline: none;
}

.input::placeholder {
  color: #6E718A;
}

.error {
  margin-top: 15px;
  font-size: 16px;
  line-height: 10px;
  color: #EB5757;
}

.errorInput {
  border: 1px solid #EB5757;
}
