.container {
  padding: 8px 24px;
  border: 1px solid #bb6bd9;
  border-radius: 20px;
  line-height: 16px;
  color: #bb6bd9;
  background: transparent;
  transition: all 200ms ease-in-out;
  margin: auto;
}

.container:hover {
  color: #fff;
  background: #bb6bd9;
}
