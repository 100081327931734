.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 4rem;
  overflow: hidden;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
  background-color: #272A3F;
  border-radius: 1.5rem;
  max-width: 100%;
  max-height: 100%;
  width: 720px;
  height: 340px;
  box-shadow: 0 32px 64px -8px rgba(0, 0, 0, 0.32);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  gap: 8px;
  overflow: auto;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #F9F9F9;
}

.description {
  font-size: 20px;
  line-height: 28px;
  color: #F9F9F9;
}

.actions {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 32px;
}

.actionPrimary {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 48px;
  gap: 12px;
  width: 312px;
  height: 152px;
  background: #36394F;
  border-radius: 16px;
  cursor: pointer;
}

.actionSecondary {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 48px;
  gap: 12px;
  width: 312px;
  height: 152px;
  background: linear-gradient(180deg, #CB1CB1 0%, #E010C2 100%);
  border-radius: 16px;
  cursor: pointer;
}

.actionPrimary > img, .actionSecondary > img {
  width: 80px;
  height: 80px;
  mix-blend-mode: lighten;
  filter: invert(1);
  scale: 1.2;
}

.actionPrimary > p, .actionSecondary > p {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #F9F9F9;
}
