.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.form > h2 {
  font-size: 36px;
  font-weight: 600;
  line-height: 130%;
}

.form > p {
  margin-top: 16px;
  font-size: 20px;
  line-height: 130%;
}

.form > input {
  width: 100%;
  margin-top: 30px;
  padding-bottom: 10px;
  border: 0;
  border-bottom: 1px solid #272A3F;
  color: #272A3F;
  font-size: 22px;
  line-height: 28px;
  outline: 0;
  text-align: center;
  transition: border-bottom-color 200ms ease-in-out;
}

.form > input::placeholder {
  color: #E0E0E0;
}

.form > input:focus {
  border-bottom: 1px solid #D416B9;
}

.form > input:invalid:not(:focus) {
  border-bottom: 1px solid #E0E0E0;
}

.form > p.errorMessage {
  margin: auto;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #EB5757;
  min-height: 22px;
}

.controls {
  margin-top: auto;
  display: flex;
  gap: 20px;
}

.controls > .submitBtn {
  flex: 1;
  padding: 19px 22px;
  color: #FFF;
  background: #D416B9;
  font-size: 18px;
  font-weight: 600;
  border-radius: 67px;
  border: 0;
  outline: 0;
  transition: background-color 200ms ease-in-out, outline 200ms ease-in-out;
  cursor: pointer;
}

.controls > .submitBtn[disabled] {
  background: #E0E0E0;
  cursor: default;
}

.controls > .submitBtn:hover:not([disabled]) {
  background: #D416B9BF;
}

.controls > .submitBtn:focus, .submitBtn:focus-visible {
  outline: 2px solid #9631FB;
}

.form > .signIn {
  margin-top: 18px;
  font-size: 14px;
}

.form > .signIn > a {
  color: #D416B9;
}
