.container {
  display: flex;
  flex-wrap: nowrap;
  gap: 13px;
  margin: 41px auto 0;
}

.inputContainer {
  width: 47px;
  height: 66px;
  border: 0;
  background: #F0F0F0;
  border-radius: 8px;
  color: #272A3F;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  position: relative;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.09);
  outline: 0;
  transition: opacity 200ms ease-in-out, box-shadow 200ms ease-in-out, background 200ms ease-in-out;
  caret-color: #D416B9;
  caret-shape: underscore;
}

.inputContainer:placeholder-shown:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border-bottom: 1px solid red;
}

.inputContainer:focus, .inputContainer:not(:placeholder-shown) {
  background: #FFFFFF;
  box-shadow: 0 6px 12px rgba(212, 22, 185, 0.23);
}

.inputContainer::placeholder {
  color: #E0E0E0;
}
