.containerWithBackground {
  border-radius: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #494C66;
  color: #F2F2F2;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
}

.containerWithBackground:hover {
  opacity: 0.7;
}

.darkVersion {
  background: #272A3F;
}

.disabled {
  pointer-events: none;
}
