.search {
  background: #36394F;
  border: 0;
  border-radius: 20px;
  box-sizing: border-box;
  width: 30%;
  padding: 14px 22px;
  font-size: 16px;
  color: #FFF;
  outline: 1px solid transparent;
  transition: outline 200ms ease-in-out;
}

.search:focus, .search:active {
  outline: 1px solid #BB6BD9;
}

.export-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #D416B9;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 24px;
  margin-left: auto;
  border: 2px solid #D416B9;
  background: transparent;
  border-radius: 52px;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
}

.export-btn:hover {
  opacity: 0.7;
}

.product-image {
  width: 67px;
  height: 67px;
  border-radius: 10px;
  object-fit: cover;
  box-sizing: border-box;
  background: #FFF;
  color: #585d88;
  flex-shrink: 0;
}

svg.product-image {
  padding: 10px;
}

.product-name {
  margin-left: 29px;
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ag-root-wrapper {
  border: 0 !important;
  background: transparent !important;
  overflow: hidden;
}

.ag-header {
  border: 0 !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: transparent !important;
}

.ag-pinned-left-cols-container {
  filter: drop-shadow(4px 0px 9px rgba(26, 28, 44, 0.39));
  z-index: 1;
}

.ag-header-container {
  background: #272A3F;
}

.ag-header-cell {
  color: #6E718A;
  font-weight: 600;
}

.ag-header-cell-resize:after {
  height: 46% !important;
  top: calc(50% - 23%) !important;
  background-color: #36394F !important;
}

.ag-row {
  border: 0 !important;
  color: #FFF;
  background: transparent !important;
  cursor: pointer;
  overflow: hidden;
}

.ag-row-hover .ag-cell {
  background-color: #505470 !important;
}

.ag-row-hover:before {
  display: none !important;
}

.ag-cell {
  border: 0 !important;
  border-bottom: 1px solid #4f5265 !important;
  display: flex;
  align-items: center;
  font-size: 16px;
  background-color: #36394F !important;
  transition: background-color 200ms ease-in-out;
  color: #FFF;
}

.ag-dnd-ghost {
  background-color: #36394F !important;
}

.ag-dnd-ghost-icon, .ag-dnd-ghost-label {
  color: #FFF !important;
}

.ag-header-cell.ag-header-cell-moving {
  background-color: #36394F !important;
}

.ag-overlay-wrapper {
  color: #FFF;
}

.ag-overlay-loading-wrapper {
  background-color: #36394F !important;
  border-radius: 20px;
  overflow: hidden;
}

.ag-pinned-left-header {
  background: #272A3F;
  border-top-left-radius: 20px;
  border-right: 0;
}

.filters {
  max-width: 80vw;
  padding: 25px 50px;
  background-color: #EFF0F8;
  border-radius: 20px;
}

.filters > h1 {
  color: #272A3F;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 26px;
}

.filters > div {
  display: flex;
  gap: 34px;
}

.filtersBtn {
  margin-left: auto;
  background: #9B51E0;
  width: 142px;
  height: 44px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFF;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.filtersBtn:hover {
  opacity: 0.7;
}

.applyBtn {
  margin-left: auto;
  margin-top: 20px;
  width: 121px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 128px;
  background: linear-gradient(180deg, #CB1CB1 0%, #E010C2 100%);
  border-radius: 52px;
  color: #FFF;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
}

.applyBtn:hover {
  opacity: 0.7;
}

.ag-paging-panel {
  background: #36394F;
  color: #F5F5F5;
}

.ag-icon {
  color: #F5F5F5;
}

.table-pagination {
  height: 64px;
  background: #36394F;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 24px;
  gap: 16px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.table-pagination > * {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6E718A;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.table-pagination > *:hover {
  color: #DADADA;
}

.table-pagination > svg {
  padding-bottom: 2px;
}

.table-pagination > span.disabled {
  pointer-events: none;
}

.table-pagination > span.active {
  color: #F5F5F5;
}

@media screen and (max-width: 1440px) {
  .search {
    padding: 12px 19px;
    font-size: 14px;
  }

  .export-btn {
    gap: 6px;
    font-size: 14px;
    padding: 6px 18px;
    border: 1px solid #D416B9;
  }

  .product-name {
    margin-left: 24px;
    font-size: 16px;
  }

  .product-image {
    width: 48px;
    height: 48px;
  }

  .ag-cell {
    font-size: 14px;
  }
}
