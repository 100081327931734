.container {
  display: flex;
  flex-direction: column;
  padding-top: 88px;
  min-height: 100vh;
  color: #FFF;
  background: linear-gradient(166.89deg, #33364C 3.33%, #222434 28.55%, #25283A 53.5%, #0C0E1B 89.92%);
  box-sizing: border-box;
  user-select: none;
}

.container * {
  box-sizing: border-box;
}
